<template>
  <v-data-table
    must-sort
    fixed-header
    show-expand
    :loading="loading"
    :headers="headers"
    :items="organisms"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 15, 50, 100, 200 ],
      itemsPerPageText: `${ $options.filters.capitalize($tc('organism', 2)) } ${ $t('per page') }:`,
      showFirstLastPage: true
    }"
    no-data-text="No organisms found"
    class="elevation-2"
  >
    <template #top>
      <v-row
        justify="space-between"
        class="pt-3 px-3 mb-n3 "
      >
        <v-col md="3">
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
        <v-col md="2">
          <table-filter
            :value.sync="options.filter.class"
            :choices="classes"
            label="Class"
            icon="fal fa-bacteria"
            item-value="name"
            item-text="name"
            multiple
          />
        </v-col>
        <v-col md="2">
          <category-filter
            :value.sync="options.filter.category"
          />
        </v-col>
        <v-col md="2">
          <table-filter
            :value.sync="options.filter.status"
            :choices="['Valid', 'Invalid', 'Unclassified']"
            label="Status"
            icon="fal fa-badge-check"
          />
        </v-col>
      </v-row>
    </template>
    <template #item.category="{ item }">
      <v-chip
        v-if="item.category"
        :key="`organism-category-chip-${item.category.id}`"
        small
        v-text="item.category.name"
      />
    </template>
    <template #item.class="{ item }">
      <v-tooltip
        v-if="item.class"
        top
      >
        <template #activator="{ on }">
          <v-avatar
            size="28"
            :color="stringColor(item.class)"
            v-on="on"
          >
            <i
              style="height: 20px"
              :class="classes.find(x => x.name === item.class).icon"
            />
          </v-avatar>
        </template>
        {{ item.class | capitalize }}
      </v-tooltip>
    </template>
    <template #item.SNOMED_full_name="{ item }">
      {{ item.SNOMED_full_name | truncate }}
    </template>
    <template #item.significant_sources="{ item }">
      <span
        v-for="source in orderBy(item.category ? item.category.significant_sources : item.significant_sources, 'source')"
        :key="source"
        class="mr-1"
      >
        <source-icon
          :key="source"
          :source="source"
          :size="18"
        />
      </span>
    </template>
    <template #item.controversial_sources="{ item }">
      <span
        v-for="source in orderBy(item.category ? item.category.controversial_sources : item.controversial_sources, 'source')"
        :key="source"
        class="mr-1"
      >
        <source-icon
          :key="source"
          :source="source"
          :size="18"
        />
      </span>
    </template>
    <template #item.invalid="{ item }">
      <v-icon
        v-if="item.invalid"
        color="red"
        small
      >
        fas fa-times-circle
      </v-icon>
      <v-icon
        v-else-if="item.new"
        color="brown lighten-1"
        small
      >
        fas fa-circle-star fa-beat
      </v-icon>
      <v-icon
        v-else
        color="green"
        small
      >
        fas fa-check-circle
      </v-icon>
    </template>
    <template #item.actions="{ item }">
      <v-btn
        small
        ripple
        icon
        plain
        @click="$emit('toggle-right-drawer', item, true)"
      >
        <v-icon>fal fa-ellipsis-v</v-icon>
      </v-btn>
    </template>
    <template #expanded-item="{ item }">
      <td :colspan="headers.length">
        <v-row>
          <v-col>
            <v-card outlined>
              <v-card-title>Notes</v-card-title>
              <v-card-text>
                {{ item.notes }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </td>
    </template>
    <template #page-text="props">
      Showing {{ props.pageStart }} - {{ props.pageStop }} of {{ props.itemsLength }} organisms
    </template>
  </v-data-table>
</template>
<script>
  import Vue2Filters from 'vue2-filters';

  export default {
    components: {
      SourceIcon: () => import('@/components/source/SourceIcon.vue'),
      SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
      TableFilter: () => import('@/components/tables/filters/TableFilter.vue'),
      CategoryFilter: () => import('@/components/tables/filters/CategoryFilter.vue'),
    },
    mixins: [
      Vue2Filters.mixin,
    ],
    props: {
      search: {
        type: String,
        default: null,
      },
    },
    metaInfo: {
      title: 'Organisms',
    },
    data () {
      return {
        loading: false,
        options: {
          search: null,
          filter: this.$route.query,
          sortBy: ['name'],
          sortDesc: [false],
        },
        totalItems: null,
        headers: [
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Category',
            value: 'category',
          },
          {
            text: 'Class',
            value: 'class',
          },
          {
            text: 'Significant Sources',
            value: 'significant_sources',
          },
          {
            text: 'Controversial Sources',
            value: 'controversial_sources',
          },
          {
            value: 'invalid',
            align: 'center',
          },
          {
            sortable: false,
            align: 'right',
            value: 'actions',
          },
        ],
        organisms: [],
        classes: [
          {
            name: 'bacteria',
            icon: 'fal fa-bacterium',
          },
          {
            name: 'fungus',
            icon: 'fal fa-mushroom',
          },
          {
            name: 'parasite',
            icon: 'fal fa-bug',
          },
          {
            name: 'virus',
            icon: 'fal fa-virus',
          },
          {
            name: 'yeast',
            icon: 'fal fa-bread-slice',
          },
          {
            name: 'prion disease',
            icon: 'fal fa-egg-fried',
          },
        ],
      }
    },
    watch: {

      options: {
        deep: true,
        handler () {
          this.fetchOrganisms()
        },
      },
    },
    methods: {
      fetchOrganisms () {
        this.loading = true
        const promise = this.axios.get('admin/organisms', {
          params:
            {
              ...{
              page: this.options.page,
              count: this.options.itemsPerPage,
              sort: this.options.sortBy[0],
              order: this.options.sortDesc[0] ? 'desc' : 'asc',
              search: this.options.search,
            },
              ...this.$route.query,
            },
        })
        return promise.then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchOrganisms()
            return
          }
          this.$set(this.options, 'page', response.data.current_page)
          this.$set(this.options, 'itemsPerPage', Number(response.data.per_page))
          this.totalItems = response.data.total
          this.organisms = response.data.data
        }).catch(error => {
          this.$toast.error(error)
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
